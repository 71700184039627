
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.ElectricityEmissionVM()
    }) model: OM.ElectricityEmissionVM;

    localModel: OM.ElectricityEmissionVM = new OM.ElectricityEmissionVM();
    groups: string[] = [];

    newValuePerYear:  OM.ElectricityEmissionValuePerYear = new OM.ElectricityEmissionValuePerYear();

    @Prop() callback: any;

    created() {
        this.localModel.identifier = this.model.identifier;
        this.localModel.country = this.model.country;
        this.localModel.countryShort = this.model.countryShort;
        this.localModel.group = this.model.group;
        
        this.model.valuesPerYear.forEach(element => {
            this.localModel.valuesPerYear.push({...element});
        });

        ElectricityEmissionClient.getAllGroups()
        .then(x => {
            this.groups = x;
        })
    }

    addNewValue() {
        this.localModel.valuesPerYear.push(this.newValuePerYear);
        this.newValuePerYear = new OM.ElectricityEmissionValuePerYear();
    }
    removeOldValue(index: number) {
        this.localModel.valuesPerYear.splice(index, 1);
    }

    get canSave(){
        return this.localModel.country && this.localModel.valuesPerYear.length > 0;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
