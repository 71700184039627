
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Electricity extends Vue {

    items: OM.ElectricityEmissionVM[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        ElectricityEmissionClient.getAllConfigurations()
        .then(x => {
            this.items = x;
            this.loaded = true;
        })
    }

    editConfiguration(item: OM.ElectricityEmissionVM) {
        if(!item)
            item = new OM.ElectricityEmissionVM();

        this.$opModal.show(EditModal, {
            model: item,
            callback: (updatedModel: OM.ElectricityEmissionVM) => {
                ElectricityEmissionClient.editConfiguration(updatedModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }

}
